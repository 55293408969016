import React, { useMemo } from "react";
import { VictoryPie } from "victory";
import { format } from "date-fns";
import colors from "../../assets/colors";
import {
  ImportedNutritionCalendarItem,
  CalendarDay,
  MacronutrientType,
  ClientNutritionTarget,
  WorkoutSyncSourceType,
} from "../../types/gqlTypes";
import Loader from "../Loader";
import Modal from "../Modal";
import CalendarIcons, { CalendarIconType } from "../Icons/CalendarIcons";

interface Props {
  date: Date;
  isLoading: boolean;
  itemId: string;
  calendarDay: CalendarDay;
  clientNutritionTarget: ClientNutritionTarget;
  onClose: () => void;
}

const CalendarNutritionModal = (props: Props) => {
  const {
    date,
    itemId,
    calendarDay,
    isLoading,
    clientNutritionTarget,
    onClose,
  } = props;

  const events = [
    {
      title: "Calories",
      color: "#625375",
      targetKey: "calories",
      key: MacronutrientType.Calories,
      subtitleLabel: "cals",
      label: "calories",
    },
    {
      title: "Protein",
      color: "#3446fb",
      targetKey: "protein",
      key: MacronutrientType.Protein,
      subtitleLabel: "g",
      label: "g",
    },
    {
      title: "Fat",
      color: "#ff1500",
      targetKey: "fat",
      key: MacronutrientType.Fat,
      subtitleLabel: "g",
      label: "g",
    },
    {
      title: "Carbs",
      color: "#20c867",
      targetKey: "carbs",
      key: MacronutrientType.Carbs,
      subtitleLabel: "g",
      label: "g",
    },
  ];

  const item = calendarDay?.items.find(
    (item) => item?.id === itemId
  ) as ImportedNutritionCalendarItem;

  const sourceDescriptions: { [key in WorkoutSyncSourceType]: string } = {
    [WorkoutSyncSourceType.Cronometer]: "Cronometer",
    [WorkoutSyncSourceType.Healthkit]: "Apple HealthKit",
    [WorkoutSyncSourceType.GoogleFit]: "Google Fit",
    [WorkoutSyncSourceType.Caliber]: "Caliber",
    [WorkoutSyncSourceType.SamsungHealth]: "Samsung Health",
    [WorkoutSyncSourceType.HealthConnect]: "Health Connect",
  };

  const values = useMemo(() => {
    const obj = {};
    item?.macronutrientEvents?.forEach((item) => {
      obj[item.macronutrient] = Math.round(item.value);
    });
    return obj;
  }, [itemId, isLoading]);

  return (
    <Modal onClose={onClose} closeOnDimmerClick>
      <div
        className="d-flex flex-column"
        style={{
          width: 422,
          height: 513,
          padding: 24,
          position: "relative",
        }}
      >
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "48%",
              left: "48%",
            }}
          >
            <Loader />
          </div>
        )}
        <div
          className="medium-bold"
          style={{
            fontSize: "12px",
            lineHeight: "24px",
            color: colors.caliber_secondary,
          }}
        >
          {`${format(date, "EEEE")}・${format(date, "MMM dd yyyy")}`}
        </div>

        <div
          className="d-flex align-items-center medium-bold"
          style={{
            color: colors.caliber_secondary,
            fontSize: "20px",
            lineHeight: "24px",
            marginTop: 5,
          }}
        >
          <div style={{ width: 24, height: 24, marginRight: 10 }}>
            <CalendarIcons type={CalendarIconType.NUTRITION} />
          </div>
          Nutrition
          <div
            className="circular-font"
            style={{
              position: "absolute",
              top: 40,
              right: 24,
              fontSize: "12px",
              lineHeight: "14px",
              color: colors.caliber_gray_11,
            }}
          >
            Imported from
            <div
              className="medium-bold"
              style={{
                position: "absolute",
                top: 19,
                right: 0,
                fontSize: "16px",
                lineHeight: "14px",
                color: colors.caliber_secondary,
                whiteSpace: "nowrap",
              }}
            >
              {sourceDescriptions[item?.source]}
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 35,
          }}
          className="d-flex flex-wrap"
        >
          {events.map(
            ({ title, key, label, targetKey, subtitleLabel, color }, index) => {
              const x = values?.[key] || 0;
              const y = clientNutritionTarget?.[targetKey] || x;
              const value = Math.round((x * 100) / y);
              const data = [{ y: value }, { y: 100 - value }];
              const colorScale = [color, "#F5F7F9"];
              return (
                <div
                  key={title}
                  className="d-flex flex-column"
                  style={{
                    width: 175,
                    height: 175,
                    position: "relative",
                    backgroundColor: colors.caliber_white,
                    border: `1px solid ${colors.caliber_secondary_gray_5}`,
                    borderRadius: 8,
                    marginRight: index % 2 === 0 && 24,
                    marginBottom: index % 2 === 0 && 24,
                  }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center bold"
                    style={{
                      position: "absolute",
                      fontSize: "24px",
                      lineHeight: "24px",
                      color: colors.caliber_secondary,
                      letterSpacing: "-0.47px",
                      width: 175,
                      top: 45,
                    }}
                  >
                    {x}
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center medium-bold"
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      lineHeight: "10px",
                      color: colors.caliber_secondary_gray_38,
                      letterSpacing: "-0.38px",
                      width: 175,
                      top: 70,
                    }}
                  >
                    {label}
                  </div>
                  <div
                    style={{
                      width: 96,
                      height: 96,
                      marginTop: 16,
                      marginLeft: 40,
                    }}
                  >
                    <VictoryPie
                      data={data}
                      colorScale={colorScale}
                      width={80}
                      height={80}
                      padding={0}
                      innerRadius={31}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center bold"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: colors.caliber_secondary,
                      letterSpacing: "-0.47px",
                      width: 175,
                      marginTop: 15,
                    }}
                  >
                    {title}
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center bold"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      color: colors.caliber_secondary_gray_38,
                      letterSpacing: "-0.47px",
                      width: 175,
                    }}
                  >
                    {x} / {y} {subtitleLabel}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </Modal>
  );
};
export default CalendarNutritionModal;
