import {
  ChatRoom,
  ClientConsultation,
  ClientPackageType,
  TextGoal,
  User,
  ZoomCall,
} from "../../../types/gqlTypes";

export const CLIENT_DETAIL_LOAD_SUCCESS = "CLIENT_DETAIL_LOAD_SUCCESS";
export const CLIENT_DETAIL_LOAD_FAIL = "CLIENT_DETAIL_LOAD_FAIL";
export const CLIENT_DETAIL_LOADING = "CLIENT_DETAIL_LOADING";

export const CLIENT_CALLS_LEFT_SAVING_SUCCESS =
  "CLIENT_CALLS_LEFT_SAVING_SUCCESS";
export const CLIENT_CALLS_LEFT_SAVING_FAIL = "CLIENT_CALLS_LEFT_SAVING_FAIL";
export const CLIENT_CALLS_LEFT_SAVING = "CLIENT_CALLS_LEFT_SAVING";

export const CHANGE_CLIENT_EMAIL_SAVING_SUCCESS =
  "CHANGE_CLIENT_EMAIL_SAVING_SUCCESS";
export const CHANGE_CLIENT_EMAIL_SAVING_FAIL =
  "CHANGE_CLIENT_EMAIL_SAVING_FAIL";
export const CHANGE_CLIENT_EMAIL_SAVING = "CHANGE_CLIENT_EMAIL_SAVING";

export const ZOOM_CALL_DELETING_SUCCESS = "ZOOM_CALL_DELETING_SUCCESS";
export const ZOOM_CALL_ADDING_SUCCESS = "ZOOM_CALL_ADDING_SUCCESS";
export const ZOOM_CALL_UPDATING_SUCCESS = "ZOOM_CALL_UPDATING_SUCCESS";
export const ZOOM_CALL_SAVING_FAIL = "ZOOM_CALL_SAVING_FAIL";
export const ZOOM_CALL_SAVING = "ZOOM_CALL_SAVING";

export const CLIENT_SALES_PACKAGE_END_DATE_SAVING_SUCCESS =
  "CLIENT_SALES_PACKAGE_END_DATE_SAVING_SUCCESS";
export const CLIENT_SALES_PACKAGE_END_DATE_SAVING_FAIL =
  "CLIENT_SALES_PACKAGE_END_DATE_SAVING_FAIL";
export const CLIENT_SALES_PACKAGE_END_DATE_SAVING =
  "CLIENT_SALES_PACKAGE_END_DATE_SAVING";

export const INITIAL_TERM_CLIENT_COACHING_PACKAGE_START_DATE_SAVING_SUCCESS =
  "INITIAL_TERM_CLIENT_COACHING_PACKAGE_START_DATE_SAVING_SUCCESS";
export const INITIAL_TERM_CLIENT_COACHING_PACKAGE_START_DATE_SAVING_FAIL =
  "INITIAL_TERM_CLIENT_COACHING_PACKAGE_START_DATE_SAVING_FAIL";
export const INITIAL_TERM_CLIENT_COACHING_PACKAGE_START_DATE_SAVING =
  "INITIAL_TERM_CLIENT_COACHING_PACKAGE_START_DATE_SAVING";

export interface ClientDetailState {
  id: string;
  isLoading: boolean;
  isSaving?: boolean;
  isGoalLoading: boolean;
  user: User;
  bodyWeight: number;
  bodyFatPercent: number;
  textGoal: any;
  clientConsultation: ClientConsultation;
  lastMessage?: ChatRoom;
  callsLeft?: number;
  startDate?: string;
  packageEndDate?: string;
  clientPackageType?: ClientPackageType;
  selectedZoomCall?: ZoomCall;
  zoomCalls?: ZoomCall[];
}

export interface ClientDetailAction {
  type: string;
  user?: User;
  bodyWeight?: number;
  bodyFatPercent?: number;
  textGoal?: TextGoal;
  clientConsultation?: ClientConsultation;
  notes?: string;
  lastMessage?: ChatRoom;
  callsLeft?: number;
  startDate?: string;
  packageEndDate?: string;
  clientPackageType?: ClientPackageType;
  email?: string;
  selectedZoomCall?: ZoomCall;
  zoomCalls?: ZoomCall[];
}
