import { UnitType } from "../../../types/gqlTypes";
import { RESET_CLIENT_STATE } from "../../actions/ClientPlans/types";
import {
  TEXT_GOAL_LOADING,
  TEXT_GOAL_LOAD_FAIL,
  TEXT_GOAL_LOAD_SUCCESS,
} from "../../actions/Goals/TextGoal/types";
import { REASSIGN_CLIENT_TRAINER_SUCCESS } from "../../actions/ReassignClientTrainer/types";
import { UPDATE_NOTES_SUCCESS } from "../../actions/UpdateNotes/types";
import {
  CHANGE_CLIENT_EMAIL_SAVING,
  CHANGE_CLIENT_EMAIL_SAVING_FAIL,
  CHANGE_CLIENT_EMAIL_SAVING_SUCCESS,
  CLIENT_CALLS_LEFT_SAVING,
  CLIENT_CALLS_LEFT_SAVING_FAIL,
  CLIENT_CALLS_LEFT_SAVING_SUCCESS,
  ClientDetailAction,
  ClientDetailState,
  CLIENT_DETAIL_LOADING,
  CLIENT_DETAIL_LOAD_FAIL,
  CLIENT_DETAIL_LOAD_SUCCESS,
  ZOOM_CALL_SAVING,
  ZOOM_CALL_SAVING_FAIL,
  ZOOM_CALL_UPDATING_SUCCESS,
  ZOOM_CALL_ADDING_SUCCESS,
  ZOOM_CALL_DELETING_SUCCESS,
  CLIENT_SALES_PACKAGE_END_DATE_SAVING_SUCCESS,
  INITIAL_TERM_CLIENT_COACHING_PACKAGE_START_DATE_SAVING_SUCCESS,
} from "../../actions/ClientDetail/types";

const initialState: ClientDetailState = {
  id: undefined,
  isLoading: false,
  isGoalLoading: false,
  bodyWeight: null,
  bodyFatPercent: null,
  clientConsultation: null,
  textGoal: undefined,
  lastMessage: null,
  callsLeft: null,
  packageEndDate: null,
  clientPackageType: null,
  selectedZoomCall: null,
  zoomCalls: null,
  user: {
    age: null,
    userEnabled: null,
    timezone: null,
    settings: {
      hasNewNotification: false,
      unitWeight: UnitType.Lb,
      unitBodystat: UnitType.In,
    },
  },
};

const clientDetailState = (
  state = initialState,
  action: ClientDetailAction
) => {
  switch (action.type) {
    case CHANGE_CLIENT_EMAIL_SAVING_SUCCESS: {
      const newUser = { ...state.user };
      newUser.email = action.email;
      return {
        ...state,
        user: newUser,
        isSaving: false,
      };
    }
    case CHANGE_CLIENT_EMAIL_SAVING_FAIL:
      return {
        ...state,
        isSaving: false,
      };
    case CHANGE_CLIENT_EMAIL_SAVING:
      return {
        ...state,
        isSaving: true,
      };
    case CLIENT_CALLS_LEFT_SAVING_SUCCESS: {
      return {
        ...state,
        callsLeft: action.callsLeft,
        isSaving: false,
      };
    }
    case CLIENT_CALLS_LEFT_SAVING_FAIL:
      return {
        ...state,
        isSaving: false,
      };
    case CLIENT_CALLS_LEFT_SAVING:
      return {
        ...state,
        isSaving: true,
      };
    case REASSIGN_CLIENT_TRAINER_SUCCESS: {
      return {
        ...state,
        user: action.user,
      };
    }
    case UPDATE_NOTES_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          notes: action.notes,
        },
      };
    case TEXT_GOAL_LOADING:
      return {
        ...state,
        isGoalLoading: true,
      };
    case TEXT_GOAL_LOAD_SUCCESS:
      return {
        ...state,
        isGoalLoading: false,
        textGoal: action.textGoal,
      };
    case TEXT_GOAL_LOAD_FAIL:
      return {
        ...state,
        isGoalLoading: false,
      };
    case CLIENT_DETAIL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CLIENT_DETAIL_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.user,
        textGoal: action.textGoal,
        bodyWeight: action.bodyWeight,
        bodyFatPercent: action.bodyFatPercent,
        lastMessage: action.lastMessage,
        clientConsultation: action.clientConsultation,
        callsLeft: action.callsLeft,
        startDate: action.startDate,
        packageEndDate: action.packageEndDate,
        clientPackageType: action.clientPackageType,
        zoomCalls: action.zoomCalls,
      };
    case CLIENT_DETAIL_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        clientConsultation: null,
        lastMessage: null,
        user: {},
        goals: {},
        bodyWeight: null,
        bodyFatPercent: null,
      };
    case RESET_CLIENT_STATE:
      return {
        ...state,
        isLoading: false,
        clientConsultation: null,
        clientPackageType: null,
        lastMessage: null,
        user: {},
        goals: {},
        textGoal: null,
        bodyWeight: null,
        zoomCalls: null,
        updatedZoomCall: null,
      };
    case ZOOM_CALL_SAVING:
      return {
        ...state,
        isLoading: true,
      };
    case ZOOM_CALL_UPDATING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedZoomCall: action.selectedZoomCall,
        zoomCalls: state.zoomCalls.map((zoomCall) => {
          if (zoomCall.id === action.selectedZoomCall.id) {
            return action.selectedZoomCall;
          }

          return zoomCall;
        }),
      };
    case ZOOM_CALL_ADDING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedZoomCall: action.selectedZoomCall,
        zoomCalls: [...state.zoomCalls, action.selectedZoomCall],
      };
    case ZOOM_CALL_SAVING_FAIL:
      return {
        ...state,
        isLoading: false,
        selectedZoomCall: null,
        message: (action as any).data.message,
      };
    case ZOOM_CALL_DELETING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedZoomCall: action.selectedZoomCall,
        zoomCalls: state.zoomCalls.filter(
          (zoomCall) => zoomCall.id !== action.selectedZoomCall.id
        ),
      };
    case CLIENT_SALES_PACKAGE_END_DATE_SAVING_SUCCESS:
      return {
        ...state,
        packageEndDate: action.packageEndDate,
      };
    case INITIAL_TERM_CLIENT_COACHING_PACKAGE_START_DATE_SAVING_SUCCESS:
      return {
        ...state,
        startDate: action.startDate,
        packageEndDate: action.packageEndDate,
      };
    default:
      return state;
  }
};

export default clientDetailState;
