import colors from "../../assets/colors";
import React, { useState } from "react";
import SimpleButton from "../SimpleButton";
import EditIcon from "../Icons/EditIcon";
import DatePicker from "react-datepicker";

interface Props {
  name: string;
  dateString: string;
  allowEdit: boolean;
  onSaveDate: (date: Date) => void;
}

interface DateInputFieldProps {
  date?: string;
  onClick?: (date?: any) => void;
}
const DateInputField = (props: DateInputFieldProps) => {
  const { date, onClick } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onClick();
        }
      }}
      className="d-flex flex-column justify-content-center align-items-center medium-bold pointer"
      style={{
        backgroundColor: colors.caliber_gray_bg,
        padding: "2px 10px",
        borderRadius: 4,
        minWidth: 90,
        margin: 0,
        fontSize: "12px",
        lineHeight: "16px",
      }}
    >
      {date || "--"}
    </div>
  );
};

export const CoachingPackageDateField = (props: Props) => {
  const { name, dateString, allowEdit, onSaveDate } = props;
  const [isEditingDate, setIsEditingDate] = useState(false);
  const date = new Date(`${dateString}T00:00:00`);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return (
    <div
      style={{ margin: "12px 0px" }}
      className="d-flex justify-content-between align-items-center"
    >
      <div
        className="medium-bold"
        style={{
          fontSize: "12px",
          lineHeight: "16px",
          color: colors.caliber_secondary,
        }}
      >
        {name}
      </div>
      {isEditingDate ? (
        <DatePicker
          onChange={(date: Date) => {
            onSaveDate(date);
            setIsEditingDate(false);
          }}
          onCalendarClose={() => {
            setIsEditingDate(false);
          }}
          customInput={<DateInputField date={formattedDate} />}
          selected={date}
          startOpen={true}
        />
      ) : (
        <div className="d-flex">
          {allowEdit && (
            <SimpleButton
              onClick={() => {
                setIsEditingDate(true);
              }}
              className="d-flex align-items-center justify-content-center"
              style={{
                marginRight: 6,
              }}
            >
              <EditIcon />
            </SimpleButton>
          )}
          <div
            className="bold"
            style={{
              padding: "1px 10px",
              borderRadius: 4,
              fontSize: "12px",
              backgroundColor: colors.caliber_white,
              color: colors.caliber_secondary,
            }}
          >
            {formattedDate || "n/a"}
          </div>
        </div>
      )}
    </div>
  );
};
